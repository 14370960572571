import React, { useState } from "react"
import { graphql } from "gatsby"
import { Box, Container, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getCategoryPath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageHero from "./pageHero"
import { useFavicon } from "../hooks/useFavicon"
import FooterServiceInformation from "../components/blocks/footerServiceInformation"
import PageSubtitle from "../components/pageSubtitle"
import PageGridList from "../components/pageGridList"
import RichContentStructuredText from "../components/richContentStructuredText"
import ContactForm from "../components/blocks/contactForm"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"

const Page = ({
  data: {
    page,
    categories,
    familyCategories,
    parentCategory,
    footerServiceInformation,
    site,
  },
}) => {
  const favicon = useFavicon().site.faviconMetaTags
  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const [hasProducts, setHasProducts] = useState(
    page.products.length > 0 ? 1 : 0
  )

  const checkParentCategory = (parentCategories, category) => {
    return JSON.parse(JSON.stringify(parentCategories)).nodes.filter(
      familyCategory => {
        let foundParent = familyCategory.categories.filter(categories => {
          return (
            JSON.parse(JSON.stringify(categories)).id ===
            JSON.parse(JSON.stringify(category)).id
          )
        })
        if (foundParent.length > 0) return familyCategory
      }
    )[0]
  }

  const foundCategoryParent = checkParentCategory(familyCategories, page)

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getCategoryPath(page, locale.locale, familyCategories),
    }
  })
  console.log(page.heroImage)

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero
        page={page}
        image={page.heroImage}
        familyCategory={parentCategory.nodes[0]}
      />
      <PageSubtitle subtitle={page.subtitle} />
      <Box sx={{ mt: [0] }}>
        <Container>
          <PageGridList
            type="category"
            familyCategories={familyCategories}
            categories={categories}
            parentCategory={parentCategory}
            foundCategoryParent={foundCategoryParent}
            page={page}
            catalogue={page.catalogue}
            productCatalog={page.productCatalog}
          />
        </Container>
      </Box>
      <Box>
        <Container>
          <RichContentStructuredText text={page.body} />
        </Container>
      </Box>
      <Container>
        <ContactForm
          title="Richiedi maggiori informazioni"
          subtitle="Compila il form"
          description="Verrai contattato da un nostro consulente"
        />
      </Container>
      <Box>
        <FooterServiceInformation services={footerServiceInformation.service} />
      </Box>
    </Layout>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 0,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" sx={{ mb: 0, "&:last-child": { mb: 0 } }} />
}

export default Page

export const query = graphql`
  query ProductCategoryQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsProductCategory(id: { eq: $id }) {
      id
      locale
      title
      shortTitle
      subtitle
      subsubtitle
      shortDescription
      slug
      thumbnailImage {
        gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
      }
      products {
        socafGear
        id
        title
        shortTitle
        shortDescription
        locale
        slug
        availability
        thumbnailImage {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
        fullWidthThumbnail
        images {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
      }
      productCatalog {
        id
        title
        model {
          apiKey
        }
        link {
          ... on DatoCmsExternalLink {
            id
            url
          }
        }
        body {
          value
        }
        image {
          gatsbyImageData
        }
      }
      catalogue {
        ... on DatoCmsDocument {
          id
          title
          documents {
            url
            title
            format
          }
          meta {
            firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
          }
          model {
            apiKey
          }
        }
      }
      model {
        apiKey
      }
      body {
        value
        blocks {
          __typename
          ... on DatoCmsContactForm {
            id: originalId
            kicker
            subtitle
            title
            newsletterDescription
            model {
              apiKey
            }
          }

          ... on DatoCmsTextBlock {
            id: originalId
            title
            darkBackground
            body {
              value
            }
            alignLeftOrTop
            model {
              apiKey
            }
          }
        }
      }
      ...AllProductCategorySlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      image {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 30
          }
        )
      }
      heroImage {
        gatsbyImageData(
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 60
          }
        )
        mobile: gatsbyImageData(
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            fit: "crop"
            ar: "16:10"
            h: "800"
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 30
          }
        )
      }
      model {
        apiKey
      }
    }

    familyCategories: allDatoCmsProductFamily(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        shortTitle
        locale
        slug
        categories {
          slug
          id
          title
          products {
            id
            title
            slug
          }
        }
        model {
          apiKey
        }
      }
    }

    parentCategory: allDatoCmsProductFamily(
      filter: {
        categories: { elemMatch: { id: { in: [$id] } } }
        slug: { ne: null }
        locale: { eq: $locale }
      }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        slug
        categories {
          slug
          id
          title
          products {
            id
            title
            slug
          }
        }
        model {
          apiKey
        }
      }
    }

    categories: allDatoCmsProductCategory(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        ...ProductCategoryPageDetails
        model {
          apiKey
        }
      }
    }

    footerServiceInformation: datoCmsServiceInformation {
      id
      service {
        ... on DatoCmsRichContent {
          ...RichContent
        }
      }
    }
  }

  fragment AllProductCategorySlugLocales on DatoCmsProductCategory {
    _allSlugLocales {
      value
      locale
    }
  }
`
